.page-content {
  display: grid;
  grid-gap: var(--gap);
  grid-template-columns: 1fr;
  overflow: visible;
  padding: var(--gap) var(--page-content-horizontal-padding);
  width: 100vw;

  &.center {
    align-items: center;
    justify-items: center;
  }

  &.hero {
    padding: 0;
  }

  &.cols-1 {
    align-items: stretch;
    display: flex;
    flex-direction: column;

    & > *:not(:last-child) {
      margin-bottom: var(--gap);
    }
  }

  &.cols-2 {
    grid-template-columns: var(--layout-2-cols);
  }

  &.cols-3 {
    grid-template-columns: var(--layout-3-cols);
  }

  &.gray {
    background-color: var(--color-page-background);
  }

  &.elevated {
    background-color: var(--section-background-color);
    border-radius: var(--section-border-radius);
    box-shadow: var(--section-box-shadow);
    margin: 0 var(--page-content-horizontal-padding);
    padding: var(--gap);
    width: auto;
  }

  &:only-child {
    flex-grow: 1;
  }

  &:last-child {
    padding-bottom: var(--gap-huge);
  }

  > img {
    object-fit: cover;
    object-position: center;
  }
}
