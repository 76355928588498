.page-section {
  background-color: var(--section-background-color);
  border: var(--section-border);
  border-radius: var(--section-border-radius);
  box-shadow: var(--section-box-shadow);
  max-width: var(--width-container);
  overflow: visible;
  padding: var(--section-padding);
  position: relative;

  &.narrow {
    & > * {
      margin: 0 auto;
      max-width: 25rem;
    }
  }

  &.cols-2,
  &.cols-3 {
    display: grid;
    gap: var(--gap);
    grid-template-columns: 1fr;
  }

  &.cols-2 {
    grid-template-columns: var(--layout-2-cols);
  }

  &.cols-3 {
    grid-template-columns: var(--layout-3-cols);
  }

  &.center {
    place-items: center;
  }

  img,
  svg {
    max-width: 100%;
  }

  > a[name]:first-child {
    position: absolute;
    scroll-margin-top: calc(var(--gap-large) + var(--header-height));
  }

  > form:first-child {
    padding-top: var(--gap);
  }
}
