.page-section.error-section {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  margin: 0 auto;
  padding: var(--gap-huge) var(--gap);
  width: 100%;

  &:only-child {
    flex-grow: 1;
    height: 100%;
  }
}
