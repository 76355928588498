.throbber {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 3rem 1rem 2rem;

  & > div {
    animation: bouncing-loader 0.6s infinite alternate;
    background: var(--color-brand);
    border-radius: 50%;
    height: 0.75rem;
    margin: 0 0.125rem;
    width: 0.75rem;

    &:nth-child(2) {
      animation-delay: 0.2s;
    }

    &:nth-child(3) {
      animation-delay: 0.4s;
    }
  }
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translate3d(0, -1rem, 0);
  }
}

.long-time-pending {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
