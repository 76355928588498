.page-header {
  align-items: center;
  background-color: var(--section-background-color);
  box-sizing: border-box;
  color: var(--color-text);
  display: flex;
  justify-content: space-between;
  min-height: var(--header-height);
  overflow-x: hidden;
  overflow-y: visible;
  padding: var(--gap-small) var(--page-content-horizontal-padding);
  position: relative;
  width: 100%;

  @media (--height-laptop) {
    min-height: 0;
  }

  &.center {
    align-items: center;
    display: flex;
    flex-grow: 0;
    justify-items: center;
  }

  &.wide {
    flex-direction: row;
    justify-content: space-between;
    padding: var(--gap) 0;

    @media (--tablet-) {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  .title-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    top: 0;

    & > h1 {
      font-weight: bold;
      margin: 0;
    }

    &:empty {
      display: none;
    }
  }

  h2 {
    margin: 0;
  }

  p {
    font-size: var(--font-size-small);
    text-align: inherit;
  }

  @media (--tablet-) {
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
  }
}
