.custom-html {
  :matches(p, ul, ol, table, div) + :matches(h3, h4, h5) {
    margin-top: var(--gap-large);
  }
  /*
  -- TABLE DEFAULTS --------------------------------------------------------
  */

  table {
    border-collapse: collapse;
    border-spacing: 0;
    display: block;
    overflow-x: auto;
    width: 100%;

    thead,
    tfoot,
    th {
      font-weight: var(--bold);
    }

    td,
    th {
      border: 1px dotted var(--color-border);
      padding: var(--gap-tiny);
      text-align: left;

      &.number {
        text-align: right;
      }
    }

    td {
      vertical-align: top;
    }
  }
}

.alternate-content {
  background-color: var(--alternate-content-background-color);
  color: var(--alternate-content-text-color);
  padding: var(--gap);
}
/*
  -- LIST WITH ICONS --------------------------------------------------------
  */

ul.has-icons {
  --icon-size: 4rem;
  --icon-color: currentColor;

  list-style: none;
  margin: 0;
  padding: 0;

  & > li {
    align-items: center;
    display: flex;
    position: relative;

    & > svg path:not([fill]) {
      fill: var(--icon-color);
    }

    & > svg:first-child,
    & > img:first-child {
      height: var(--icon-size);
      margin-right: var(--gap-small);
      object-fit: contain;
      object-position: center;
      width: var(--icon-size);
    }

    & > :nth-child(2) {
      margin: 0;
      padding: 0;
    }
  }

  &.has-third-block {
    li {
      flex-wrap: wrap;

      & > :nth-child(2) {
        max-width: calc(100% - var(--icon-size) - var(--gap-small));
      }

      & > :nth-child(3) {
        flex-grow: 1;
        width: 100%;
      }
    }
  }
}
/*
  -- LAYOUT HELPER --------------------------------------------------------
    <div class="aside-image left">
      <img />
      <div />
    </div>
    -- OR --
    <div class="aside-image right">
      <div />
      <img />
    </div>
  --------------------------------------------------------------------------
  */

.aside-image,
.has-aside {
  margin: var(--gap) auto;

  @media (--tablet) {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &.left {
      & > .aside,
      & > img,
      & > svg {
        margin: 0 var(--gap) var(--gap) 0;
      }
    }

    &.right {
      & > .aside,
      & > img,
      & > svg {
        margin: 0 0 var(--gap) var(--gap);
        order: 2;
      }
    }
  }
}
